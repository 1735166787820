import { bindable } from 'aurelia-framework';
import { Security } from 'common/security';
import { Notifier } from 'common/ui';
import { I18n } from 'common/i18n';
import Dropzone from 'dropzone';
import environment from '../../../../config/environment.json';

export class UploadFile {
    static inject = [Element, Security, Notifier, I18n];
    _security;
    _notifier;
    _i18n;

    @bindable({ changeHandler: '_setFormAction' }) type;
    @bindable({ changeHandler: '_setFormAction' }) memberId;
    @bindable({ changeHandler: '_setFormAction' }) qs;

    _dropzone;
    dropzoneEl;
    formAction;

    constructor(element, security, notifier, i18n) {
        this._element = element;
        this._security = security;
        this._notifier = notifier;
        this._i18n = i18n;
	}

    attached() {
        this._setFormAction();
        this._initialize();
    }

    _setFormAction() {
        this.formAction = `${environment.api}/api/import/file`;
        const qs = [];
        if (this.qs) {
            const qsArray = this.qs.split('&');
            qsArray.forEach(x => qs.push(x));
        }
        if (this.type) qs.push(`type=${encodeURIComponent(this.type)}`);
        if (this.memberId) qs.push(`memberId=${encodeURIComponent(this.memberId)}`);
        if (qs.length) {
            this.formAction += `?${qs.join('&')}`;
        }
        if (this._dropzone) this._dropzone.options.url = this.formAction;
    }

	_initialize() {
		const me = this;
		const timezoneOffset = new Date().getTimezoneOffset();

		this._dropzone = new Dropzone(this.dropzoneEl, {
            url: this.formAction,
			dictDefaultMessage: this._i18n.tr(`import-file-message-${this.type}`),
			paramName: 'file',
			maxFilesize: 50, // MB
			headers: { 'Authorization': 'Bearer ' + this._security.token, 'X-LEGACY-TimezoneOffset': timezoneOffset },
			acceptedFiles: '.xlsx,.xls,.csv',
			maxFiles: 1,
            timeout: 180000,
			init: function () {
				this.on('addedfile', function (file) {
                    me.errors = [];
                    me.processing = true;
                    me.policyStatus = undefined;
				});
				this.on('success', function (file, response) {
					this.removeFile(file);
                    me.processing = false;
                    if (response && response.succeeded) {
    					me._notifier.successText('File imported');
                        me._element.dispatchEvent(new CustomEvent('success', { bubbles: true, detail: response.model }));
                    } else {
                        me.errors = JSON.parse(response.failureData);
                        if (response.model) {
                            me._element.dispatchEvent(new CustomEvent('file-definition-not-found', { bubbles: true, detail: { file: response.model } }));
                        } else {
                            me._notifier.errorText(response.message);
                        }
                    }
				});
				this.on('error', function (file, error, x) {
                    this.removeAllFiles();
					me._notifier.errorText('Error importing the file.<br/>' + error.message);
                    me.processing = false;
				});
			}
		});
	}
}
