import { customElement, bindable, bindingMode } from 'aurelia-framework';

@customElement('filter-input')
export class FilterInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay}) value;
    @bindable theme;
    @bindable placeholder = 'search';
    @bindable focus = false;
    @bindable disabled;

    constructor() {}

    clear() {
        this.value = undefined;
    }
}
